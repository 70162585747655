<template>
  <div class="common_sub_container">
    <h2>TYPE OF GOODS</h2>
    <section>
      <div class="two_tabs">
        <a
          @click="showTab1=true,showTab2=false"
          v-bind:class="{ active: showTab1 }"
          class="tab_buttons"
        >ADD</a>
        <a
          @click="showTab2=true,showTab1=false"
          v-bind:class="{ active: showTab2 }"
          class="tab_buttons"
        >VIEW</a>
      </div>
      <TypeOfGoodAdd v-if="showTab1" @redirect="showTab2=true,showTab1=false"/>
      <TypeOfGoodView v-if="showTab2" />
    </section>
  </div>
</template>
<script>
import TypeOfGoodAdd from "./TypeOfGood_Add.vue";
import TypeOfGoodView from "./TypeOfGood_View.vue";
export default {
  name: "x",
  components: {
    TypeOfGoodAdd,
    TypeOfGoodView
  },
  created(){
    this.$emit("breadCrumbData",["Master Data","Type Of Goods"]);
  },
  data() {
    return {
      showTab1: true,
      showTab2: false
    };
  }
};
</script>
<style  scoped >
</style>