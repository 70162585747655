<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <ValidationObserver ref="observer" slim>
        <table>
          <tr>
            <th>Type</th>
            <th>Mode of Dispatch</th>
            <th class="width_40px">Weight</th>
            <th class="width_40px">Length</th>
            <th class="width_40px">Width</th>
            <th class="width_40px">Height</th>
            <th class="width_40px">
              M
              <sup>3</sup>
            </th>
            <th class="width_40px">Cubic/Kg</th>
            <th class="width_40px">Dangerous Goods</th>
            <th class="width_40px">Food</th>
            <th class="width_40px">Volume</th>
            <th>Action</th>
          </tr>
          <tr v-for="(good,i) in goods" :key="i">
            <td>
              <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <input
                    type="text"
                    placeholder="Enter Character limit 30"
                    :maxlength="max"
                    v-model="good.type"
                    :disabled="!good.is_edit_mode"
                    :class="errors[0] ? 'warning-border':''"
                  />
                </div>
              </ValidationProvider>
            </td>
            <td>

          <!--    <ValidationProvider name="name" rules="required" v-slot="{ errors }" slim>

            <div class="label_and_element_wrapper">
              <select v-model="good.dispatch_mode" :disabled="!good.is_edit_mode" :class="errors[0] ? 'warning-border':''">
                <option value>Select Mode of Dispatch</option>
                <option v-for="(mode,i) in modes" :key="'mode'+i" :value="mode.id">{{mode.name}}</option>
              </select>
            </div>

              </ValidationProvider>-->
                <div class="multychk_wrap">
                  <div class="chk_group">
                    <label v-for="(mode,i) in good.modes" :key="'dispatch'+i"  >
                      <input type="checkbox" v-model="mode.checked" :true-value="mode.id" :false-value="false"  :disabled="!good.is_edit_mode"/>
                      <span>{{mode.name}}</span>
                    </label>
                  </div>
                </div>            

            </td>
            <td>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character limit 30"
                  :maxlength="max"
                  v-model="good.weight"
                  :disabled="!good.is_edit_mode"
                />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character limit 30"
                  :maxlength="max"
                  v-model="good.length"
                  :disabled="!good.is_edit_mode"
                  @change="updatem3('l',$event,good)"
                />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character limit 30"
                  :maxlength="max"
                  v-model="good.width"
                  :disabled="!good.is_edit_mode"
                  @change="updatem3('w',$event,good)"
                />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character limit 30"
                  :maxlength="max"
                  v-model="good.height"
                  :disabled="!good.is_edit_mode"
                  @change="updatem3('h',$event,good)"
                />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character limit 30"
                  :maxlength="max"
                  v-model="good.m3"
                  :disabled="!good.is_edit_mode"
                />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character limit 30"
                  :maxlength="max"
                  v-model="good.cubic_kg"
                  :disabled="!good.is_edit_mode"
                />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <input type="checkbox" class="chk_prefered" v-model="good.is_dg"  true-value="1" false-value="0" :disabled="!good.is_edit_mode" />
              </div>
            </td>
             <td>
            <div class="label_and_element_wrapper">
              <input
                type="checkbox"
                class="chk_prefered"
                
                v-model="good.is_food" true-value="1" false-value="0"
                :disabled="!good.is_edit_mode"
              />
            </div>
            </td>
            <td>
           <!--    <div class="label_and_element_wrapper">
                <input
                  type="checkbox"
                  class="chk_prefered"
                  :id="good.id"
                  :checked="good.volume == 'N'"
                  disabled
                />
              </div> -->

                <div class="label_and_element_wrapper">
                <input
                  type="checkbox"
                  class="chk_prefered"
                  :id="good.id"
                  v-model="good.volume"  true-value="1" false-value="0"
                 :disabled="!good.is_edit_mode"
                />
              </div>
            </td>
            <td class>
              <a class="table_action_btn" title="DELETE" @click="deleteGoods(good.id)">DELETE</a>
              <a
                class="table_action_btn"
                title="CANCEL"
                v-if="good.is_edit_mode"
                @click="cancelGoods()"
              >CANCEL</a>
              <a
                class="table_action_btn"
                title="UPDATE"
                v-if="good.is_edit_mode"
                @click="updateGoods(good)"
              >UPDATE</a>
              <a
                class="table_action_btn"
                title="EDIT"
                v-if="!good.is_edit_mode"
                @click="good.is_edit_mode = true"
              >EDIT</a>
            </td>
          </tr>
        </table>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
export default {
  name: "TypeOfGood",
  components: {},
  data() {
    return {
      max: 30,
      makeEditable: false,
      cancelEditable: true,
      goods: [],
      dispatches:[],
      modes: [],
      checkedmode: false
    };
  },
  created() {
    this.fetchTypeOfGoods();
  },
  methods: {

    updatem3(unit,event,good) {
      if(unit == 'l') 
        good.length = event.target.value;
  
      if(unit == 'w')
          good.width = event.target.value;
         
      if(unit == 'h')
         good.height = event.target.value;

         good.m3=((good.length * good.width * good.height)/100).toFixed(3);

         good.cubic_kg = good.m3;
              
    },

    fetchTypeOfGoods() {
      this.axios
        .get("/api/goods/")
        .then(async (response) => {
          let mode_of_dispatch = await this.axios.get("api/dispatch");
          this.goods = response.data.goods.map(good => {
            good.is_edit_mode = false;
            good.modes = mode_of_dispatch.data.mode_of_dispatch.map(element => {
              return {
                id:element.id,
                name:element.name,
                checked: good.modes.find(goodelement => goodelement.id == element.id) ? element.id : false
              }
            });
            return good;
          });
          // this.goods = response.data.goods.map(element => {
          //   element.is_edit_mode = false;
          //     element.dispatch_mode = element.modes.map(goodsmodes => {
          //       this.axios.get("/api/dispatch/")
          //       .then(response => {
          //           this.dispatches = response.data.mode_of_dispatch.map(dispatchmode =>{
          //               if(goodsmodes.id == dispatchmode.id){
          //                 this.checkedmode = true;
          //               } else {
          //                 this.checkedmode = false;
          //               }
          //             return dispatchmode;
          //           });
          //       })
          //       .catch(error =>{
          //           this.toast.error();
          //       });
          //     return goodsmodes;
          //     });
          //   return element;
          // });
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchModeOfDispatch(){
      this.axios.get("/api/dispatch/")
      .then(response => {
        console.log(response);
          this.dispatches = response.data.mode_of_dispatch;
      })
      .catch(error =>{
          this.toast.error();
      });
    },

      

    updateGoods(data) {
      // console.log(data.id);
      data.mode_of_dispatch = [];
      data.modes.forEach(element => {
        if(element.checked){
          data.mode_of_dispatch.push(element.id);
        }
      });
      console.log(data);
      this.$refs.observer.validate();
      this.axios
        .put("/api/goods/" + data.id, data)
        .then(response => {
          this.toast.success(response.data.msg);
          data.is_edit_mode = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteGoods(id) {
      if (confirm("Are you sure you want to delete this type of goods?")) {
        this.axios
          .delete("/api/goods/" + id)
          .then(response => {
            this.toast.success(response.data.msg);
            this.fetchTypeOfGoods();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    cancelGoods() {
      this.fetchTypeOfGoods();
    }
  }
};
</script>
<style>
.multychk_wrap {
  width: 100%;
  height: auto;
  padding: 5px;
}
.multychk_wrap .chk_group {
  padding: 5px;
  background: #fff;
  border: 1px solid #ccc;
  height: auto;
}
.multychk_wrap .chk_group label {
  height: 40px;
  padding: 5px;
  margin-bottom: 10px;
  background: #efefef;
  display: block;
}
.multychk_wrap .chk_group label:last-child {
  margin-bottom: 0;
}
.multychk_wrap .chk_group label span {
  display: inline-block;
  color: #333;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  position: relative;
  top: -3px;
}
.multychk_wrap .chk_group label input[type="checkbox"] {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #333;
  position: relative;
  top: 2px;
}
</style>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
@media screen and (max-width: 1600px) {
  .scoped_cover_div {
    min-width: 1500px;
    padding-right: 20px;
  }
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #06A5ED;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
</style>